import { v4 as uuidv4 } from "uuid";

const userData = [
  {
    id: 1,
    name: "Pranjul",
    uniqueId: uuidv4(),
    email: "pranjul@gamil.com",
    status: "Pending",
    phone: "498439084328",
    gender: "Female",
    verify: "No",
    payment: 545,
  },
  {
    id: 2,
    name: "Mayank",
    uniqueId: uuidv4(),
    email: "mayank@gamil.com",
    status: "Pending",
    phone: "498439084328",
    gender: "Male",
    verify: "No",
    payment: 545,
  },
  {
    id: 3,
    name: "Karan",
    uniqueId: uuidv4(),
    email: "karan@gamil.com",
    status: "Pending",
    phone: "498439084328",
    gender: "Male",
    verify: "Yes",
    payment: 545,
  },
  {
    id: 4,
    name: "Tushar",
    uniqueId: uuidv4(),
    email: "tushar@gamil.com",
    status: "Pending",
    phone: "498439084328",
    gender: "Male",
    verify: "Yes",
    payment: 545,
  },
  {
    id: 5,
    name: "Anil",
    uniqueId: uuidv4(),
    email: "anil@gamil.com",
    status: "Pending",
    phone: "498439084328",
    gender: "Male",
    verify: "No",
    payment: 545,
  },
  {
    id: 6,
    name: "Kanchan",
    uniqueId: uuidv4(),
    email: "kanchan@gamil.com",
    status: "Pending",
    phone: "498439084328",
    gender: "Female",
    verify: "No",
    payment: 545,
  },
  {
    id: 7,
    name: "Mansi",
    uniqueId: uuidv4(),
    email: "mansi@gamil.com",
    status: "Pending",
    phone: "498439084328",
    gender: "Female",
    verify: "No",
    payment: 545,
  },
  {
    id: 8,
    name: "Rahul",
    uniqueId: uuidv4(),
    email: "rahul@gamil.com",
    status: "Pending",
    phone: "498439084328",
    gender: "Male",
    verify: "No",
    payment: 545,
  },
  {
    id: 9,
    name: "Sachin",
    uniqueId: uuidv4(),
    email: "sachin@gamil.com",
    status: "Pending",
    phone: "498439084328",
    gender: "Male",
    verify: "No",
    payment: 545,
  },
  {
    id: 10,
    name: "Mohit",
    uniqueId: uuidv4(),
    email: "mohit@gamil.com",
    status: "Pending",
    phone: "498439084328",
    gender: "Male",
    verify: "No",
    payment: 545,
  },
  {
    id: 11,
    name: "Ritik",
    uniqueId: uuidv4(),
    email: "ritik@gamil.com",
    status: "Pending",
    phone: "498439084328",
    gender: "Male",
    verify: "No",
    payment: 545,
  },
];
export { userData };
