import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { List, ListItem } from '@mui/material';
import "../../src/Components/Css/faq.css"

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,

  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
  borderBottom: '1px solid rgba(0, 0, 0, .125)',
}));

export default function Faq() {
  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (

    <div>
      <div>
      <img src='/images/faq1.jpeg' className='faq-bg-img' />
       <Typography className='faq-img-heading' variant='h6'>
       Indian e-Visa - Frequently Asked Questions
        </Typography>
        </div>
      <div className=' p-5'>
        {/* <h2 className='mt-3 mb-5'>India e-Visa - Frequently Asked Questions</h2> */}
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" sx={{ backgroundColor: expanded === 'panel1' ? "aliceblue" : "" }}>
            <Typography>What is an Indian eVisa?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              An Indian eVisa is an electronic travel authorization that allows foreign nationals to enter India for tourism, business, medical, or conference purposes. It's an alternative to the traditional visa stamped in your passport.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} className="mt-1">
          <AccordionSummary aria-controls="panel2d-content" id="panel2d-header" sx={{ backgroundColor: expanded === 'panel2' ? "aliceblue" : "" }}>
            <Typography> What types of Indian eVisas are available?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              There are three main types of Indian eVisas:
            </Typography>
            <ul>
              <li> e-Tourist Visa: For tourism and casual visits. </li>
              <li>e-Business Visa: For business-related visits and meetings.</li>
              <li>e-Medical Visa: For medical treatments and procedures.</li>
            </ul>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} className="mt-1">
          <AccordionSummary aria-controls="panel3d-content" id="panel3d-header" sx={{ backgroundColor: expanded === 'panel3' ? "aliceblue" : "" }}>
            <Typography>What is the processing time for an Indian eVisa?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              The processing time for an Indian eVisa can vary, but it is usually processed within 72 hours. It's advisable to apply well in advance of your travel date.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')} className="mt-1">
          <AccordionSummary aria-controls="panel4d-content" id="panel4d-header" sx={{ backgroundColor: expanded === 'panel4' ? "aliceblue" : "" }}>
            <Typography>How long is an Indian eVisa valid for?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              The validity of an Indian eVisa depends on the type you obtain. Generally, e-Tourist and e-Business visas are valid for 1 year, while e-Medical visas are valid for 60 days. Each type may have different maximum stay durations and entry allowances.            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')} className="mt-1">
          <AccordionSummary aria-controls="panel5d-content" id="panel5d-header" sx={{ backgroundColor: expanded === 'panel5' ? "aliceblue" : "" }}>
            <Typography>Is there a multiple-entry option for Indian eVisas?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Yes, many Indian eVisas allow for multiple entries during their validity period. However, the specific entry conditions depend on the type of eVisa you obtain.            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')} className="mt-1">
          <AccordionSummary aria-controls="panel6d-content" id="panel6d-header" sx={{ backgroundColor: expanded === 'panel6' ? "aliceblue" : "" }}>
            <Typography>What should I do if my Indian eVisa application is denied?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              If your eVisa application is denied, you may consider reapplying with corrected or additional documentation. Ensure that you meet all the eligibility requirements and provide accurate information in your application.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')} className="mt-1">
          <AccordionSummary aria-controls="panel7d-content" id="panel7d-header" sx={{ backgroundColor: expanded === 'panel7' ? "aliceblue" : "" }}>
            <Typography>Can I work in India with an eVisa?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              No, eVisas are not intended for employment purposes. If you plan to work in India, you will need to apply for an appropriate employment visa.            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')} className="mt-1">
          <AccordionSummary aria-controls="panel8d-content" id="panel8d-header" sx={{ backgroundColor: expanded === 'panel8' ? "aliceblue" : "" }}>
            <Typography>What is the maximum stay allowed on an Indian eVisa?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              The maximum stay allowed on an Indian eVisa varies based on the type: </Typography>
            <ul>
              <li>eTourist Visa and eBusiness Visa: Up to 90 days per visit.</li>
              <li>eMedical Visa: Up to 60 days per visit.</li>
              <li>eConference Visa: The duration of the conference or 30 days, whichever is less.</li>
            </ul>
          </AccordionDetails>
        </Accordion>

        <Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')} className="mt-1">
          <AccordionSummary aria-controls="panel9d-content" id="panel9d-header" sx={{ backgroundColor: expanded === 'panel9' ? "aliceblue" : "" }}>
            <Typography>Is it possible to apply for an eVisa on arrival in India?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              No, Indian eVisas must be obtained online before traveling to India. There is no provision for obtaining an eVisa on arrival.            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion expanded={expanded === 'panel10'} onChange={handleChange('panel10')} className="mt-1">
          <AccordionSummary aria-controls="panel10d-content" id="panel10d-header" sx={{ backgroundColor: expanded === 'panel10' ? "aliceblue" : "" }}>
            <Typography>Can I extend my eVisa while in India?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Generally, eVisas cannot be extended. If you wish to stay longer in India, you may need to leave the country and reapply for a new eVisa or apply for a regular visa through the Indian Embassy or Consulate.            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion expanded={expanded === 'panel11'} onChange={handleChange('panel11')} className="mt-1">
          <AccordionSummary aria-controls="panel11d-content" id="panel11d-header" sx={{ backgroundColor: expanded === 'panel11' ? "aliceblue" : "" }}>
            <Typography>What documents are needed for an Indian eVisa application?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              The specific requirements may vary depending on the type of eVisa, but generally, you'll need a valid passport, a recent passport-sized photo, and other supporting documents as required for your chosen category (e.g., business letters for Business eVisa, medical documents for Medical eVisa).            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion expanded={expanded === 'panel12'} onChange={handleChange('panel12')} className="mt-1">
          <AccordionSummary aria-controls="panel12d-content" id="panel12d-header" sx={{ backgroundColor: expanded === 'panel12' ? "aliceblue" : "" }}>
            <Typography>Do I need a printed copy of my eVisa when I arrive in India?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              It's advisable to carry a printed copy of your eVisa, although it may not always be required. However, having a physical copy can help in case of any issues.            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
}