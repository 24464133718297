import { Box, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import SideMenu from "../../Components/SideMenu/index";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { Link, useNavigate } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import axios from "axios";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Footer() {
  const navigate = useNavigate()
  const [footerData, setFooterData] = useState([]);
  useEffect(() => {
    getData();
  }, []);
  const getData = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/Footer`)
      .then((res) => {
        setFooterData(res.data.footer);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }));

  const handleDelete = (id) => {
    axios.delete(`${process.env.REACT_APP_BASE_URL}/footer/${id}`).then((res) => {
      toast.success("Footer deleted")
      getData()
    }).catch((err) => {
      toast.error("Somethign went wrong!!")
      console.log(err)
    })
  }
  return (
    <Box sx={{ display: "flex" }}>
      <SideMenu></SideMenu>
      <ToastContainer />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h5">Footers</Typography>
          <Link to="/admin/footer/create">
            <Button variant="contained">Create Footer</Button>
          </Link>
        </Box>
        <TableContainer component={Paper} style={{ marginTop: "20px" }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow style={{ backgroundColor: "#000", color: "#FFF" }}>
                <TableCell style={{ color: "#FFF" }}>Heading</TableCell>
                {/* <TableCell style={{ color: "#FFF" }} align="right">
                  category
                </TableCell> */}
                {/* <TableCell style={{ color: "#FFF" }} align="right">
                  Title
                </TableCell> */}
                <TableCell style={{ color: "#FFF" }} align="">
                  Link
                </TableCell>
                <TableCell style={{ color: "#FFF" }} align="right">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {footerData.map((item) => {
                return (
                  <TableRow
                    key={item._id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {item.heading}
                    </TableCell>
                          <TableCell align="">
                    {
                      item?.subLink?.map(el => {
                        return (
                            <span>

                              {el?.title},&nbsp;
                            </span>
                            )
                          })
                        }
                          </TableCell>
                    {/* <TableCell></TableCell>
                    <TableCell></TableCell> */}
                    <TableCell align="right" style={{ display: "flex", gap: "10px" }}>
                      <DeleteIcon onClick={() => handleDelete(item._id)} />
                      <EditIcon onClick={() => { navigate(`/admin/footer/edit/${item._id}`) }} />
                    </TableCell>
                  </TableRow>
                );
              })}



            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
}

export default Footer;
