import { Box, Button, Switch } from "@mui/material";
import React, { useEffect, useState } from "react";
import SideMenu from "../../Components/SideMenu/index";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { Link, useNavigate } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import axios from "axios";
import moment from "moment";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Blogs() {
  const navigate = useNavigate()
  const [blogData, setBlogData] = useState([]);
  useEffect(() => {
    getData();
  }, []);
  const getData = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/blog/view`)
      .then((res) => {
        console.log(res);
        setBlogData([...res.data]);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }));
  const handlePublishChange = (id,isPublish) => {
    axios.put(`${process.env.REACT_APP_BASE_URL}/blog/ispublish/${id}`).then((res)=>{
      getData()
      if(isPublish)
      {
        toast.success("Blog published")
      }else{
        toast.success("Blog unpublished")
      }
    }).catch((err)=>{
      toast.error("Somethign went wrong!!")
      console.log(err)
    })
  }
  const handleDelete = (id) => {
    axios.delete(`${process.env.REACT_APP_BASE_URL}/blog/delete/${id}`).then((res)=>{
      toast.success("Blog deleted")
      getData()
    }).catch((err)=>{
      toast.error("Somethign went wrong!!")
      console.log(err)
    })
  }
  return (
    <Box sx={{ display: "flex" }}>
      <SideMenu></SideMenu>
      <ToastContainer />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h5">Blogs</Typography>
          <Link to="/admin/blogs/create">
            <Button variant="contained">Create Blog</Button>
          </Link>
        </Box>
        <TableContainer component={Paper} style={{ marginTop: "20px" }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow style={{ backgroundColor: "#000", color: "#FFF" }}>
                <TableCell style={{ color: "#FFF" }}>Title</TableCell>
                {/* <TableCell style={{ color: "#FFF" }} align="right">
                  category
                </TableCell> */}
                <TableCell style={{ color: "#FFF" }} align="right">
                  Date
                </TableCell>
                <TableCell style={{ color: "#FFF" }} align="right">
                  Status
                </TableCell>
                <TableCell style={{ color: "#FFF" }} align="right">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              
              {blogData.map((item) => {
                return (
                  <TableRow
                    key={item._id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {item.title}
                    </TableCell>
                    <TableCell align="right">
                      {moment(item.date).format("DD-MM-YYYY")} 
                    </TableCell>
                    <TableCell align="right"> 
                      <Switch
                        checked={item.isPublished}
                        onChange={()=>{
                          handlePublishChange(item._id,!item.isPublished) 
                        }}
                        inputProps={{ "aria-label": "controlled" }} 
                      />
                    </TableCell>
                    <TableCell align="right" style={{display:"flex",gap:"10px"}}>
                    <DeleteIcon onClick={()=>handleDelete(item._id)} />
                    <EditIcon onClick={()=>{navigate(`/admin/blogs/edit/${item._id}`)}}/>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
}

export default Blogs;
