
const SaarcCountries = [
    "Afghanistan",
    "Bangladesh",
    "Bhutan",
    "Maldives",
    "Nepal",
    "Pakistan",
    "Sri Lanka"
]

export default SaarcCountries