import React from 'react'
import { Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { PaymentValidate } from "../../utils";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Loader from "../Loader";

function StripeSuccess() {
    return (
        <>
            <Stack
                sx={{
                    width: "100%",
                    height: "80vh",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    // bgcolor: "primary.main",
                }}
            >
                <Card sx={{ minWidth: 275 }}>
                    <CardContent>
                        <Typography variant="h4">Payment Successful</Typography>
                    </CardContent>
                    <CardContent className="d-flex flex-column justify-content-center align-items-center">
                        <img
                            src="https://i.pinimg.com/originals/90/13/f7/9013f7b5eb6db0f41f4fd51d989491e7.gif"
                            height={"100px"}
                            width={"200px"}
                        />
                        <b style={{ marginTop: "10px" }}>We will get back to you</b>
                    </CardContent>
                    <CardActions className="d-flex justify-content-center">
                    </CardActions>
                </Card>
            </Stack>
        </>
    )
}

export default StripeSuccess