import React, { useEffect, useState } from "react";
import {
  stack,
  Stepper,
  Step,
  StepLabel,
  Button,
  TextField,
  Box,
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
  Checkbox,
  Typography,
  Card,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormGroup,
  Container,
  Stack,
} from "@mui/material";

import { PayPalButton } from "react-paypal-button-v2";
import "../Components/Css/payCheck.css";
import { Payment, StripePayment } from "../utils";
import Loading from "./Loading";
import Loader from "./Loader";
import { useNavigate, Navigate } from "react-router-dom";

//Stripe Import and setup
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from "./CheckoutForm";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

function PaymentCheck() {
  const [isLoading, setIsLoading] = useState(false);
  const [stripePayment, setStripePayment] = useState({})
  const navigate = useNavigate();

  const handleClik = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const result = await Payment();
    if (result.name === "AxiosError") {
      setIsLoading(false);
      console.log("Error");
      navigate("/payment-error");
    } else {
      setIsLoading(false);

      window.location.replace(result.data.approverUrl);
    }
  };
  useEffect(() => {
    console.log(isLoading);
  }, isLoading);


  const handleStripe = async () => {
    setIsLoading(true);
    const result = await StripePayment();
    if (result.name === "AxiosError") {
      setIsLoading(false);
      console.log("Error");
      navigate("/payment-error");
    } else {
      setIsLoading(false);
      const options = {
        // passing the client secret obtained from the server
        clientSecret: result.data.clientSecret,
        theme: 'night'
      };
      setStripePayment({ options, price: result.data.price })
    }
  }

  if (stripePayment.options) return (
    <Elements stripe={stripePromise} options={stripePayment.options}>
      <CheckoutForm price={stripePayment.price} />
    </Elements>
  )

  return (
    <Stack
      spacing={4}
      sx={{ display: "flex", alignItems: "center", margin: 10 }}
    >
      {isLoading && <Loader />}
      <Typography variant="h4"> Make Payment</Typography>

      { }
      <div style={{
        display:"flex",
        gap:"10px"
      }}>
        <Button
          variant="contained"
          size="small"
          // color=""
          onClick={handleClik}
          style={{

            background: "#FF9933",
          }}
        >
          <img
            src="https://help.instapage.com/hc/article_attachments/360002241868/PayPal.svg.png"
            height={"50px"}
            width="210px"
          />
        </Button>
        <Button
          variant="contained"
          size="small"
          // color=""
          onClick={() => { handleStripe() }}
          style={{

            background: "#FF9933",
          }}
        >
          <img
            src="/images/stripe.png"
            style={{
              height: "auto",
              width: "210px",
              maxHeight: "80px",
              objectFit: "contain",
            }}
          />
        </Button>
      </div>
    </Stack>

  );
}

export default PaymentCheck;
