import { Box, Button, TextField } from "@mui/material";
import React, { useState } from "react";
import SideMenu from "../../Components/SideMenu/index";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DeleteIcon from '@mui/icons-material/Delete';

export default function CreateFooter() {

   
    const DrawerHeader = styled("div")(({ theme }) => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    }));

    
    const inputArr = {
            title: "",
            link: "",
        } 
    const navigate = useNavigate()
    const [heading, setHeading] = useState("")
    const [arr, setArr] = useState([inputArr])


    const addInput = () => {
        setArr([...arr, inputArr])
    };
    const removeInput = (index) => {
        const textInput = [...arr];
        textInput.splice(index, 1);
        setArr(textInput);
    };

    const handleChange = (index,e) => {
        let data = [...arr];
        data[index][e.target.name] = e.target.value;
        setArr(data);
    };

    const handleSubmit = () => {
        const body={
            'heading': heading,
            'subLink': arr
        }
        console.log(body);
        axios.post(`${process.env.REACT_APP_BASE_URL}/footer`, body).then((res) => {
            console.log(res);
            toast.success("Created!!")
            navigate("/admin/footer")
        }).catch((err) => {
            toast.error("Something Went Wrong!!")
            console.log(err)
        })
    }
    return (
        <Box sx={{ display: "flex" }}>
            <SideMenu></SideMenu>
            <ToastContainer />
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <DrawerHeader />
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography variant="h5"> Create Footer</Typography>
                    <Link to="/admin/footer/create">
                    </Link>
                </Box>
                <Box>
                    <TextField style={{ margin: "20px 0px" }} value={heading} onChange={(e) => {
                        setHeading(e.target.value)
                    }} fullWidth id="outlined-basic" label="Heading" variant="outlined" />
                    <Button variant="contained" onClick={() => addInput()}>Add Link</Button>

                    {arr.map((item, i) => {
                        return (
                            <div className="d-flex gap-3 mt-2 align-items-center ">
                                <TextField style={{ margin: "20px 0px" }} id={i} value={item.title} name="title"  onChange={event => handleChange(i, event)} fullWidth label="Link Title" variant="outlined" />
                                <TextField style={{ margin: "20px 0px" }} id={i} value={item.link} name="link" onChange={event => handleChange(i, event)} fullWidth label="Link" variant="outlined" />
                                <DeleteIcon sx={{ color: "blue", fontSize: "40px" }} onClick={removeInput} />
                            </div>
                        ); 
                    })}
                    <div style={{ margin: "20px 0px", display: 'flex', gap: "10px" }}>
                        <Button variant="contained" onClick={() => handleSubmit()}>Create</Button>
                    </div>
                </Box>
            </Box>
        </Box>
    );
}

