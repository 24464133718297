import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import ApplyForm from "./Components/ApplyForm";
import "./Components/Css/ApplyForm.css";
import "./Components/Css/Home.css";
import "./Components/Css/Nav.css";
import "./Components/Css/Status.css";
import Home from "./Components/Home";
import NavBar from "./Components/NavBar";

import AdminLogin from "../src/Admin/src/Components/AdminLogin";
import Customers from "../src/Admin/src/Pages/Customers/index";
import MultistepForm from "./Components/ApplicationForm";
import ContactUs from "./Components/ContactUs";
import PaymentCheck from "./Components/PaymentCheck";
import Status from "./Components/Status";
import DocumentsUpload from "./Components/Upload";

import Protected from "../src/Admin/src/Components/Protected";
import ContactUsAdmin from "../src/Admin/src/Pages/ContactUs/index";
import Orders from "../src/Admin/src/Pages/Orders/index";
import View from "../src/Admin/src/Pages/View/index";

import Error from "./Components/postPayment/Error";
import Success from "./Components/postPayment/Success";

import ReactGA from "react-ga";
import Blogs from "./Admin/src/Pages/Blogs";
import CreateBlog from "./Admin/src/Pages/Blogs/CreateBlog";
import EditBlog from "./Admin/src/Pages/Blogs/EditBlog";
import Footer from "./Admin/src/Pages/Footer";
import CreateFooter from "./Admin/src/Pages/Footer/CreateFooter";
import EditFooter from "./Admin/src/Pages/Footer/EditFooter";
import Articles from "./Components/Articles";
import Blog from "./Components/Blog";
import LatestPost from "./Components/Dropdown/LatestPost";
import Faq from "./Components/Faq";
import FeedbackForm from "./Components/FeedBackForm";
import CommonFooter from "./Components/Footer";
import NotFound from "./Components/NotFound";
import StripeSuccess from "./Components/postPayment/StripeSuccess";


const TRACKIN_ID = "G-4138NYQBN8";
ReactGA.initialize(TRACKIN_ID);

function App() {
  const location = useLocation();
  const isAdminRoute = location.pathname.includes("admin");

  return (
    <React.Fragment>
      {isAdminRoute && (
        <Routes>
          <Route path="/admin" element={<AdminLogin />} />

          <Route path="/admin/contact" element={<Protected />}>
            <Route path="/admin/contact" element={<ContactUsAdmin />} />
          </Route>
          <Route path="/admin/customers" element={<Protected />}>
            <Route path="/admin/customers" element={<Customers />} />
          </Route>
          <Route path="/admin/customers/:id" element={<Protected />}>
            <Route path="/admin/customers/:id" element={<View />} />
          </Route>
          <Route path="/admin/orders" element={<Protected />}>
            <Route path="/admin/orders" element={<Orders />} />
          </Route>
          <Route path="/admin/blogs" element={<Protected />}>
            <Route path="/admin/blogs" element={<Blogs />} />
            <Route path="/admin/blogs/create" element={<CreateBlog />} />
            <Route path="/admin/blogs/edit/:id" element={<EditBlog />} />
          </Route>
          <Route path="/admin/footer" element={<Protected />}>
            <Route path="/admin/footer" element={<Footer />} />
            <Route path="/admin/footer/create" element={<CreateFooter />} />
            <Route path="/admin/footer/edit/:id" element={<EditFooter />} />
          </Route>
        </Routes>
      )}

      {!isAdminRoute && <NavBar />}
      {!isAdminRoute && (
        <>
          <Routes>
            <Route path="/" exact element={<Home />} />
            <Route path="/latest-post" exact element={<LatestPost />} />
            <Route path="/status" exact element={<Status />} />
            <Route path="/apply_old" exact element={<ApplyForm />} />
            <Route path="/apply" exact element={<MultistepForm />} />
            <Route path="/blog/:slug" exact element={<Blog />} />
            <Route path="/apply/:id" exact element={<MultistepForm />} />
            <Route path="/payment/:id" exact element={<PaymentCheck />} />
            <Route path="/payment" exact element={<PaymentCheck />} />
            <Route path="/upload/:id" exact element={<DocumentsUpload />} />
            <Route path="/contactus" exact element={<ContactUs />} />
            <Route path="/success?" exact element={<Success />} />
            <Route path="/stripe/success?" exact element={<StripeSuccess />} />
            <Route path="/cancel?" exact element={<Error />} />
            <Route path="/error" exact element={<Error />} />
            <Route path="/feedback" exact element={<FeedbackForm />} />
            <Route path="*" element={<NotFound />} />
            <Route path="/articles" element={<Articles />} />
            <Route path="/faq" element={<Faq />} />
          </Routes>
          <CommonFooter />
        </>

      )}
    </React.Fragment>
  );
}

export default App;
