import { Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, TextField, Button } from '@mui/material'
import React, { useState } from 'react'
import Loader from '../../Components/Loader'
import axios from 'axios'

function TransactionDialog({ open, setOpen, transactionId, setTransactionId, applicationId, setApplicationId, getData }) {
    const [isloading, setIsloading] = useState(false)
    const markPaid = () => {
        const token = localStorage.getItem("token");
        const headers = { Authorization: `Bearer ${token}` };
        setOpen(false)
        setIsloading(true)
        axios.post(`${process.env.REACT_APP_BASE_URL}/order/mark-paid/${applicationId}`,
            {
                transactionId: transactionId
            }, {
            headers
        }).then((res) => {
            axios.get(`${process.env.REACT_APP_BASE_URL}/visa/pdf/visa/${applicationId}`, {
                headers,
            }).then((res) => {
                alert("Application Paid")
                setIsloading(false)
                setApplicationId("")
                setTransactionId("")
                getData()
            }).catch((err) => {
                alert("Error Occurred in creating pdf")
                setIsloading(false)
                setApplicationId("")
                setTransactionId("")
                getData()
            })
        }).catch((err) => {
            alert(err.response.data.message)
            setIsloading(false)
            setApplicationId("")
            setTransactionId("")
            getData()
        })
    }
    return (
        <>
            {isloading && <Loader />}
            <Dialog
                open={open}
                onClose={() => {
                    setOpen(false)
                    setTransactionId("")
                }}
            >
                <DialogTitle id="alert-dialog-title">
                    {"Enter Transaction Id"}
                </DialogTitle>
                <DialogContent>
                    <FormControl size="small" fullWidth sx={{ marginTop: 2 }}>
                        <TextField
                            sx={{ marginTop: 2 }}
                            required
                            fullWidth
                            label="Transaction Id"
                            InputLabelProps={{ shrink: true }}
                            // placeholder="Date"
                            // error={
                            //   errors.date_of_birth && touched.date_of_birth ? true : false
                            // }
                            value={transactionId}
                            onChange={(e) => { setTransactionId(e.target.value) }}
                        />
                    </FormControl>{" "}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setOpen(false)
                        setTransactionId("")
                    }}
                        variant="contained"
                        size="small"
                    >Cancel</Button>
                    <Button autoFocus disabled={transactionId.length > 0 ? false : true} onClick={() => { markPaid() }}
                        variant="contained"
                        size="small"
                    >
                        Paid
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default TransactionDialog