
const PortList = [
    {
        name:"AHMEDABAD AIRPORT",
        value:"I022"
    },
    {
        name:"AMRITSAR AIRPORT",
        value:"I032"
    },
    {
        name:"BAGDOGRA AIRPORT",
        value:"I096"
    },
    {
        name:"BENGALURU AIRPORT",
        value:"I085"
    },
    {
        name:"BHUBANESHWAR AIRPORT",
        value:"I084"
    },
    {
        name:"CALICUT AIRPORT",
        value:"I010"
    },
    {
        name:"CHANDIGARH AIRPORT",
        value:"I005"
    },
    {
        name:"CHENNAI AIRPORT",
        value:"I008"
    },
    {
        name:"CHENNAI SEAPORT",
        value:"I208"
    },
    {
        name:"COCHIN AIRPORT",
        value:"I024"
    },
    {
        name:"COCHIN SEAPORT",
        value:"I224"
    },
    {
        name:"COIMBATORE AIRPORT",
        value:"I094"
    },
    {
        name:"DELHI AIRPORT",
        value:"I004"
    },
    {
        name:"GAYA AIRPORT",
        value:"I012"
    },
    {
        name:"GOA AIRPORT",
        value:"I033"
    },
    {
        name:"GOA SEAPORT",
        value:"I283"
    },
    {
        name:"GUWAHATI AIRPORT",
        value:"I019"
    },
    {
        name:"HYDERABAD AIRPORT",
        value:"I041"
    },
    {
        name:"JAIPUR AIRPORTT",
        value:"I006"
    },
    {
        name:"KANNUR AIRPORT",
        value:"I030"
    },
    {
        name:"KOLKATA AIRPORT",
        value:"I002"
    },
    {
        name:"LUCKNOW AIRPORT",
        value:"I021"
    },
    {
        name:"MADURAI AIRPORT",
        value:"I015"
    },
    {
        name:"MANGALORE AIRPORT",
        value:"I092"
    },
    {
        name:"MANGALORE SEAPORT",
        value:"I293"
    },
    {
        name:"MUMBAI AIRPORT",
        value:"I001"
    },
    {
        name:"MUMBAI SEAPORT",
        value:"I201"
    },
    {
        name:"NAGPUR AIRPORT",
        value:"I016"
    },
    {
        name:"PORTBLAIR AIRPORT",
        value:"I077"
    },
    {
        name:"PUNE AIRPORT",
        value:"I026"
    },
    {
        name:"TIRUCHIRAPALLI AIRPORT",
        value:"I003"
    },
    {
        name:"TRIVANDRUM AIRPORT",
        value:"I023"
    },
    {
        name:"VARANASI AIRPORT",
        value:"I007"
    },
    {
        name:"VISHAKHAPATNAM AIRPORT",
        value:"I025"
    },
]

export default PortList