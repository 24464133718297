import React, { useState } from "react";
import {
  Button,
  Box,
  Card,
  Container,
  TextField,
  Typography,
  FormControlLabel,
  Radio,
  FormLabel,
  RadioGroup,
} from "@mui/material";
import { AiFillMessage } from "react-icons/ai";
import { ContactApi } from "../utils";
import Loader from "./Loader";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";

const ContactUs = () => {
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [appliedVisa, setAppliedVisa] = useState();

  const dispatch = useDispatch()
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  // const handleChangee = (event) => {
  //   const { name, value } = event.target;
  //   if (name === "country_of_birth" && value === "Others") {
  //     setIsOtherCountry(true)
  //   } else {
  //     if (name === "country_of_birth" && value !== "Others") {
  //       setIsOtherCountry(false)
  //     }
  
  //     dispatch(add1(formData))
  //   };
  // }

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();

    const result = await ContactApi(formData);
    if (result) {
      setLoading(false);
      alert("Your Query Is Successfully Submitted")
      setTimeout(function () {
        window.location.reload();
      }, 1000);
    }
  };

  return (
    <>
      {loading && <Loader />}
      <Container>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Contact India evisa requirements and process</title>
          <meta name="copyright" content="https://www.indianevisa.info/contactUs" />
          <meta name="owner" content="https://www.indianevisa.info/contactUs" />
          <link rel="canonical" href="https://www.indianevisa.info/contactUs" />
          <meta name="distribution" content="world wide" />
          <meta name="descriotion" content="Contact us for eVisa Supporting Documents for Visa Application" />
        </Helmet>
        <Card sx={{ width: "100%" }} className="card-form mt-5 mb-5">
          <Typography
            variant="h4"
            sx={{ marginTop: 3, marginLeft: 4, textAlign: "center" }}
          >
            Everything You Need to Know About Indian eVisa requirements and process
          </Typography>
          <Box
            sx={{
              // display: "flex",
              //   flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              padding: "0 25px",
              boxSizing: "border-box",
              marginBottom: "20px",
              marginTop: "30px",
            }}
          >
            <div className="row">
              <div className="col-lg-12">
                <div className="row ">
                  <div className="col-lg-6 py-2">
                    {" "}
                    <TextField
                      required
                      fullWidth
                      id="cc-name"
                      label="Your Name"
                      //   value={name}
                      name="name"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-lg-6 py-2">
                    {" "}
                    <TextField
                      required
                      fullWidth
                      id="cc-email"
                      label="Your Email"
                      //   value={email}
                      name="email"
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12 py-2">
                    {" "}
                    <TextField
                      required
                      fullWidth
                      id="cc-name"
                      label="Subject"
                      //   value={name}
                      name="subject"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12 py-2">
                    {" "}
                    <TextField
                      required
                      fullWidth
                      label="Message"
                      multiline
                      name="message"
                      onChange={handleChange}
                      inputProps={{
                        style: {
                          maxHeight: "300px",
                          minHeight: "100px",
                        },
                      }}
                    />
                  </div>
                </div>

                 <div className="d-flex justify-content-between">
              <div className="text-start">
                <FormLabel required sx={{ marginTop: 1 }}>
                  Have you applied for the Visa?
                </FormLabel>
              </div>
              <div>
                <RadioGroup
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="applied_for_visa"
                  onChange={handleChange}
                >
                  <div className="d-flex">
                    <FormControlLabel
                      value={true}
                      control={<Radio size="small" />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio size="small" />}
                      label="No"
                      defaultChecked
                    />
                  </div>
                </RadioGroup>
              </div>
            </div>{" "}

                <div className="row text-center">
                  <div className="col-lg-12">
                    {" "}
                    <Button
                      className="mt-3"
                      sx={{ paddingX: 10 }}
                      variant="contained"
                      size="large"
                      type="submit"
                      onClick={handleSubmit}
                    >
                      Submit{" "}
                    </Button>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-5 py-3 ">
              <div className="col-lg-4 text-lg-start text-center">
                <AiFillMessage className="text-primary fs-5 me-1" />
                contact@indianevisa.info
              </div>
              {/* <div className="col-lg-4 text-lg-center  text-center ">
                <AiTwotonePhone className="text-primary fs-5 me-1" />+ 01 234
                567 89
              </div> */}
              {/* <div className="col-lg-4 text-lg-end text-center">
                <AiFillMessage className="text-primary fs-5 me-1" />
                contact@indianevisa.info
              </div> */}
            </div>
          </Box>
        </Card>
      </Container>
    </>
  );
};

export default ContactUs;
