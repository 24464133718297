import React,{useState} from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';

const FeedbackForm = () => {

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = {
        name:name,
        email:email,
        message:message
    }
    console.log(data)
  };

  return (
    <Card
      style={{
        maxWidth: '800px',
        margin: '0 auto',
        padding: '24px',
        marginTop:'100px',
      }}
    >
      <CardContent>
        <div style={{ display: 'flex', justifyContent: 'center', margin:'20px' }}>
            <Typography variant='h4'>Feedback Form</Typography>
        </div>

        <form
          onSubmit={handleSubmit}
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
          }}
        >
          <TextField label="Name" variant="outlined" fullWidth required onChange={(e)=>{setName(e.target.value)}}/>
          <TextField label="Email" variant="outlined" fullWidth required onChange={(e)=>{setEmail(e.target.value)}} />
          <TextField
            label="Message"
            variant="outlined"
            multiline
            rows={4}
            fullWidth
            required
            onChange={(e)=>{setMessage(e.target.value)}}
          />
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              style={{ width: '200px' }}
            >
              Submit
            </Button>
          </div>
        </form>
      </CardContent>
    </Card>
  );
};

export default FeedbackForm;
