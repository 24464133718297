import { Box, Button, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import SideMenu from "../../Components/SideMenu/index";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DeleteIcon from '@mui/icons-material/Delete';

export default function EditFooter() {
  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }));

  const inputArr = {
    title: "",
    link: "",
  }
  const params = useParams()
  const { id } = params
  const navigate = useNavigate()
  const [heading, setHeading] = useState("")
  const [arr, setArr] = useState([inputArr])

  useEffect(() => {
    getData()
  }, [])

  const getData = () => {
    axios.get(`${process.env.REACT_APP_BASE_URL}/footer/?id=${id}`)
      .then((res) => {
        console.log(res.data);
        setHeading(res.data.footer?.heading)
        console.log(res.data?.footer?.subLink);
        setArr(res.data.footer?.subLink) 
        console.log(arr);
      }).catch((err) => {
        console.log(err)
      })
  }

  const addInput = () => {
    setArr([...arr, inputArr])
};
const removeInput = (index) => {
    const textInput = [...arr];
    textInput.splice(index, 1);
    setArr(textInput);
};

const handleChange = (index,e) => {
    let data = [...arr];
    data[index][e.target.name] = e.target.value;
    setArr(data);
};

  const handleSubmit = () => {
    let body = {
      'heading': heading,
      'subLink': arr
    }
    console.log(body);
    axios.put(`${process.env.REACT_APP_BASE_URL}/footer/${id}`, body).then((res) => {
      console.log(res)
      toast.success("Footer updated!")
      navigate("/admin/footer")
    }).catch((err) => {
      toast.error("Something Went Wrong!!")
      console.log(err)
    })
  }

  return (
    <Box sx={{ display: "flex" }}>
      <SideMenu></SideMenu>
      <ToastContainer />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h5"> Edit Footer</Typography>
          <Link to="/admin/footer/create">
          </Link>
        </Box>
        <Box>
          <TextField style={{ margin: "20px 0px" }} value={heading} onChange={(e) => {
            setHeading(e.target.value)
          }} fullWidth id="outlined-basic" label="Heading" variant="outlined" />
          <Button variant="contained" onClick={() => addInput()}>Edit/Add More Link</Button>

          {arr?.map((item, i) => {
            return (
              <div className="d-flex gap-3 mt-2 align-items-center ">
                <TextField style={{ margin: "20px 0px" }} id={i} value={item.title} name="title" onChange={event => handleChange(i, event)} fullWidth label="Link Title" variant="outlined" />
                <TextField style={{ margin: "20px 0px" }} id={i} value={item.link} name="link" onChange={event => handleChange(i, event)} fullWidth label="Link" variant="outlined" />
                <DeleteIcon sx={{ color: "blue", fontSize: "40px" }} onClick={removeInput} />
              </div>
            );
          })} 
          <div style={{ margin: "20px 0px", display: 'flex', gap: "10px" }}>
            <Button variant="contained" onClick={() => handleSubmit()}>Update</Button>
          </div>
        </Box>
      </Box>
    </Box>
  );
}
