import { Image } from '@mui/icons-material'
import { Button, Typography } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getData } from '../utils'

const Articles = () => {
    const [blogData, setBlogData] = useState([]);

    useEffect(() => {
        getDataList();
    }, [])

    const getDataList = async () =>{
        const dataList = await getData()
        setBlogData(dataList.data)
      }

    return (
        <div>
            <div className='py-5'>
                <Typography variant="h4" color="primary" className='text-center'>Articles</Typography>
                {
                blogData.map(el =>
                <div className='d-flex justify-content-center align-items-center px-3 py-5 w-100'>
                    <div className=''>
                        <img src={el?.thumbnail} width={100} height={100} alt="" className='rounded' />
                    </div>
                    <div className='d-flex flex-column px-4 w-75'>
                    <Typography variant='h5' dangerouslySetInnerHTML={{ __html: el?.title }}></Typography>
                    <div style={{height:"50px",overflow:"hidden"}}>
                    <Typography dangerouslySetInnerHTML={{ __html: el?.description }}>
                        
                        </Typography>
                        </div>
                    <Button size="small" className='justify-content-end'><Link to={`/blog/${el.slug}`} style={{textDecoration:"none",color:"#1976d2"}}>Read More...</Link></Button>
                    </div>
                     </div>
                )
                }
            </div>
        </div>
    )
}

export default Articles
