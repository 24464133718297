import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
  Button,
  Grid,
  Box,
  Container,
  Paper,
  CardActionArea,
} from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import SwiperCore, { Autoplay } from "swiper/core";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/less";
import "swiper/less/navigation";
import "swiper/less/pagination";
import "swiper/css/scrollbar";
import { experimentalStyled as styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import PaymentIcon from "@mui/icons-material/Payment";
import AvTimerIcon from "@mui/icons-material/AvTimer";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useRef, userEffect } from "react";

import ReactGA from "react-ga";
import { Helmet } from "react-helmet";
import Footer from "./Footer";
import { getData } from "../utils";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
function createData(name, visaType, fat, carbs, protein) {
  return { name, visaType, fat, carbs, protein };
}
const rows = [
  createData('eTOURIST VISA (for 30 Days)', 80),
  createData('eTOURIST VISA (for 1 Year)', 99),
  createData('eTOURIST VISA (for 5 Years)', 180),
  createData('eBUSINESS VISA', 199),
  createData('eMEDICAL VISA', 199),
  createData('eMEDICAL ATTENDANT VISA', 199),
  createData('eCONFERENCE VISA', 199),
  createData('G20 eCONFERENCE VISA', 150),
];
const cardItem = [
  {
    id: 1,
    title: "30 Days validity",
    description: " Tourist eVisa",
  },
  {
    id: 2,
    title: "1 Year validity",
    description: " Tourist eVisa",
  },
  {
    id: 3,
    title: "5 Year validity",
    description: "Tourist eVisa",
  },
  {
    id: 4,
    title: "5 Year validity",
    description: "Tourist eVisa",
  },
];
const Item = styled(Container)(({ theme }) => ({
  backgroundColor: "transparent",
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
  //   marginLeft: 8,
}));

const Home = () => {
  const [blogData, setBlogData] = useState([]);
  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    getDataList();
  }, [])

  const getDataList = async () => {
    const dataList = await getData()
    setBlogData(dataList.data)
  }

  SwiperCore.use([Autoplay, Pagination, Navigation]);
  const swiperRef = useRef(null);
  const navigate = useNavigate();

  
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Apply for indian evisa online</title>
        <link rel="canonical" href="https://www.indianevisa.info/" />
        <meta name="copyright" content="https://www.indianevisa.info/" />
        <meta name="owner" content="https://www.indianevisa.info/" />
        <meta name="distribution" content="world wide" />
        <meta name="descriotion" content="Apply online indian eVisa for all citizen Complete  guide eVisa application process and time for Your Trip to India" />
      </Helmet>
      {" "}
      <div className="bg-image" style={{ maxWidth: "100%" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            px: 2,
          }}
        >
          {" "}
          <Typography
            variant="h3"
            className="text-light text-center "
            sx={{ marginTop: 7 }}
          >
            <h1>Apply for indian evisa Your Smooth Trip <br /> Indian evisa online application</h1>
            {/* <div>Indian e-Visa Apply</div>
            <div>Apply Indian Visa Online</div> */}

            <Button
              variant="contained"
              className="mt-2 "
              size="large"
              onClick={() => {
                navigate("/apply");
              }}
            >
              Access the Online Visa Application Form
            </Button>
          </Typography>
        </Box>

        <div className="container">
          {" "}
          <Swiper
            ref={swiperRef}
            spaceBetween={20}
            breakpoints={{
              // 1300: {
              //   slidesPerView: 6,
              // },
              1024: {
                slidesPerView: 3,
              },
              768: {
                slidesPerView: 2,
              },
              412: {
                slidesPerView: 1,
              },
              320: {
                slidesPerView: 1,
                // width: 20,
              },
            }}
            slidesPerView={6}
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            pagination={{ clickable: true }}
            scrollbar={{ draggable: true }}
            effect={false}
            // autoplay={{
            //   delay: 2000,
            //   disableOnInteraction: false,
            // }}
            grabCursor={true}
          // onSlideChange={handleSlideChange}
          >
            <div>
              {cardItem.map((item, index) => (
                <SwiperSlide key={item.id}>
                  <Item key={index} className="">
                    <Card
                      sx={{
                        maxWidth: 400,
                        minWidth: 250,
                        borderRadius: "10px",
                        marginTop: 3,
                      }}
                    >
                      <CardMedia
                        component="img"
                        alt="green iguana"
                        height="100"
                        image="https://st2.depositphotos.com/1013693/8169/i/600/depositphotos_81695568-stock-photo-chinese-usa-and-shengen-european.jpg"
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h6" component="div">
                          {item.title}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {item.description}{" "}
                        </Typography>
                      </CardContent>
                      <CardActions
                        sx={{ justifyContent: "center", marginTop: -2 }}
                      >
                        <Button size="small" variant="contained" onClick={() => navigate("/apply")}>
                          Apply Now
                        </Button>
                        {/* <Button size="small">Learn More</Button> */}
                      </CardActions>
                    </Card>
                  </Item>
                </SwiperSlide>
              ))}
            </div>
          </Swiper>
        </div>
      </div>
     <div className="container">
     <div className="row py-5">
        <div className="col-lg-3 mt-2">
          <Card
            className="card-form"
            sx={{ borderRadius: 6, cursor: "pointer" }}
            onClick={() => navigate("/apply")}
          >
            <CardContent className="text-center">
              <LibraryBooksIcon sx={{ color: "#1976d2" }} className="fs-2" />
              <Typography variant="h6" sx={{ textAlign: "center" }}>
                Apply here for eVisa
              </Typography>
            </CardContent>
          </Card>
        </div>
        {/* {second: cards section } */}
        <div className="col-lg-3 mt-2 ">
          <Card
            className="card-form"
            sx={{ borderRadius: 6, cursor: "pointer" }}
            onClick={() => navigate("/status")}
          >
            <CardContent className="text-center">
              <AvTimerIcon sx={{ color: "#1976d2" }} className="fs-2" />
              <Typography variant="h6" sx={{ textAlign: "center" }}>
                Check your status
              </Typography>
            </CardContent>
          </Card>
        </div>{" "}
        <div className="col-lg-3 mt-2">
          <Card
            className="card-form"
            sx={{ borderRadius: 6, cursor: "pointer" }}
            onClick={() => navigate("/status")}
          >
            <CardContent className="text-center">
              <PaymentIcon sx={{ color: "#1976d2" }} className="fs-2" />
              <Typography variant="h6" sx={{ textAlign: "center" }}>
                Pay for eVisa Fees
              </Typography>
            </CardContent>
          </Card>
        </div>{" "}
        <div className="col-lg-3 mt-2">
          <Card
            className="card-form"
            sx={{ borderRadius: 6, cursor: "pointer" }}
            onClick={() => navigate("/status")}
          >
            <CardContent className="text-center">
              <CloudUploadIcon sx={{ color: "#1976d2" }} className="fs-2" />
              <Typography variant="h6">Upload Documents</Typography>
            </CardContent>
          </Card>
        </div>
      </div>
     </div>
      {/* { third:articles section } */}
      <div className="container">
        <div className="row py-5">
          <div className="d-flex justify-content-center px-5 ">
            <Typography variant="h4" color="primary" >Articles</Typography>
          </div>

          <div className="d-flex gap-3 justify-content-center flex-wrap">
            {
              blogData?.slice(0,3).map(el =>
                <div className=" mt-4">
                  <Link to={`/blog/${el.slug}`}>
                    <Card sx={{ maxWidth: 345, width:440 }} className="position-relative hover">
                      <CardActionArea>
                        <CardMedia
                          component="img"
                          height="300"
                          image={el?.thumbnail}
                          alt="img"
                        />
                        <CardContent className="position-absolute fixed-bottom text-white h-100 d-flex flex-column-reverse" style={{ backgroundColor: "rgb(0 0 0 / 32%)" }}>
                          <Typography gutterBottom variant="h6" component="div">
                            {el.title}
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Link>
                </div>
              )
            }
          </div>
          <div className="w-100 text-center mt-4">
          <Button variant="contained"><Link to="/articles" style={{ textDecoration: "none", color: "white" }}>See more</Link></Button>

          </div>
        </div>
      </div>
{/* {fourth section } */}
      <div className="container">
        <div className="row py-5">
          <div className="col-lg-12 my-5">
            <div className="row d-flex align-items-center ">
              <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                <div className="w-100 text-start">
                  <img src="/images/travel2.jpg" className="w-100" />
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                {/* <Card sx={{ background: "#e7e7e7" }}>
                  <CardContent> */}
                    <Typography
                      sx={{ fontSize: 14, textAlign: "justify" }}
                      color="text.secondary"
                      gutterBottom
                    >
                      <div className="mb-3">
                        {" "}
                        <h2>Explore India Hassle-Free: Apply for Indian Visa Online</h2>
                        <p>Are you considering a vacation to India and curious about the easiest way to get a  visa.  We'll walk you through the procedure for obtaining an online tourist visa for India in this article. You'll have a thorough grasp of the procedures by the conclusion, which will make your travel preparations simple.</p>
                        <p>Say goodbye to lengthy paperwork and standing in queues. Our user-friendly online platform allows you to apply for your eVisa from the comfort of your home or office. The process is simple, efficient, and tailored to your convenience.</p>
                        <p>We offer a variety of eVisa categories to suit your travel needs. Whether you're visiting for tourism, business, medical purposes, or to attend a conference, we have the right visa type for you. Choose from the e-Tourist Visa, e-Business Visa, e-Medical Visa, or e-Conference Visa.</p>
                        <p>Embark on an unforgettable journey to India and immerse yourself in its rich tapestry of sights, sounds, and flavors. Begin your adventure now by applying for your Indian eVisa. We look forward to welcoming you to this incredible land of diversity and wonder.</p>
                      </div>
                      <div className="mt-3">
                        Apart from the ease of applying for the Visa online the
                        e-Visa for India is also the quickest way to enter India.
                      </div>{" "}
                    </Typography>
                  {/* </CardContent>
                </Card> */}
              </div>


            </div>
          </div>
          <div className="col-lg-12 my-5">
            <div className="row d-flex align-items-center ">
              <div className="col-12 col-md-6 col-lg-6 col-xl-6">
             
                <Typography
                  sx={{ fontSize: 14, textAlign: "justify" }}
                  color="text.secondary"
                >
                  <div className="mt-0">
                    <h2>Indian tourist visa online today!</h2>
                    <p>The electronic Indian Visa, also known as the India e-Visa, is an online travel authorization system introduced by the Government of India. It allows foreign travelers to apply and obtain a visa electronically, eliminating the need for traditional paper-based applications and visits to Indian embassies or consulates.</p>

                    <p>The India e-Visa is available for various purposes, including tourism, business, medical treatment, and attending conferences or events. It offers different visa categories based on the traveler's intended purpose of visit, such as e-Tourist Visa, e-Business Visa, e-Medical Visa, and e-Conference Visa.</p>

                    <p>The e-Visa system streamlines the visa application process, making it more convenient and efficient for travelers. The application can be completed online by providing necessary personal, passport, and travel details, along with supporting documents such as a scanned passport bio-page and a recent photograph. Payment of the visa fee is also done online.</p>

                    <p>Once the application is submitted and approved, the e-Visa is issued electronically and sent to the applicant via email. Travelers are required to carry a printed copy of the e-Visa while traveling and present it to the immigration authorities upon arrival in India.</p>
                  </div>
                </Typography>
              
                <Button
                  size="large"
                  variant="contained"
                  onClick={() => navigate("/apply")}
                >
                  APPLY INDIAN VISA ONLINE{" "}
                </Button>
             
              </div>
              <div className="col-12 col-md-6 col-lg-6 col-xl-6">
              <div className="w-100 ">
                  <img src="/images/travel4.avif" className="w-100" />
                </div>
              </div>
            </div>
            
          </div>
        </div>

          <div className="row d-flex align-items-center my-5">
            <div className="col-12 col-md-6 col-lg-6 col-xl-6">
            <div className="w-100 text-start">
                  <img src="/images/travel.avif" className="w-100" />
                </div>
            </div>
            <div className="col-12 col-md-6 col-lg-6 col-xl-6">
              <Typography
                sx={{ fontSize: 14, textAlign: "justify" }}
                color="text.secondary"
                gutterBottom
              >
                <div className="mb-3">
                <h2>Indian visa!</h2>
                  <div >The traveller can apply for 1 of the following accessible visas depending on why they visit India.</div>
                  <div >The visitor should apply for an e-Visa for tourists to India (eVisa India) for travel and sightseeing purposes.</div>
                  <div >The traveller should apply for a Business e-Visa for India (eVisa India) to conduct business and trade.</div>
                  <div >The tourist should apply for a Medical e-Visa for India (eVisa India) to receive medical treatment.</div>
                  <div >The visitor should apply for a Medical Attendant e-Visa for India (eVisa India) to come as a medical attendant to someone receiving medical treatment here.</div>

                  <div >The application for an Indian visa can now be completed online without visiting the Indian High Commission. You can apply for an electronic visa for India online. The eVisa India can be applied online using a mobile device, computer, or tablet.</div>

                  <div >You have two alternatives when applying for an Indian visa: go to your local Indian Embassy or High Commission or <span><a href="https://www.indianevisa.info/contactUs">evisa apply online</a></span> </div>

                  <div >Please be aware that offering immigration advice necessitates a licence or permission from the appropriate authorities. We represent you and file your application following professional reviews; we do not give you immigration advice for any nation, including India, for your visa application.</div>

                  <div >The data and material on this website are all copyrighted and belong to a private company. It is privately owned. The Indian government is not at all associated with us. Only personal use is permitted for this website's services and is intended. By using this website and gaining access to it, the user consents to refrain from altering, copying, reusing, or downloading any of its components for use in commercial endeavours. Copyright applies to all information and materials on this website. We are a private website that aids and directs applicants in filing their Indian e-Visa application online; we are not in any way associated with the Government of India. The benefit of having your e-Visa application examined by our team of professionals is a further advantage of applying through our website instead of the website of the Indian Government. For our services, a small cost is required.</div>
                  
                </div>
              </Typography>
            </div>
         
        </div>

        {/* </div> */}
      </div>
{/* {fifth:price table } */}
<div className="w-100 px-5 d-flex justify-content-center py-5 w-75 overflow-auto">
     
     <table className="w-100 bg-light rounded " style={{minWidth: 400}}>
      <thead className="text-white" style={{backgroundColor:"#1976d2",fontSize:"20px"}}>
        <tr className="border">
          <th className="p-3">Visa Type</th>
          <th className="border p-3 text-end">Price($)</th>
        </tr>
      </thead>
      <tbody >
        {rows.map((row) => 
        <tr className="border-right-0 border border-black" style={{backgroundColor:"#e6f3ff"}}>
          <td className="border p-3 "><Link to="/apply" style={{textDecoration:"none"}}>{row?.name}</Link></td>
          <td className="border p-3 text-end">{row?.visaType}</td>
        </tr>
        )}
      </tbody>
     </table>

    </div>

      {/* <Footer /> */}
    </>
  );
};
export default Home;
