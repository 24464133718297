import * as React from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import axios from 'axios';


const uploadAdapter = (loader) => {
    return {
      upload: () => {
        return new Promise((resolve,reject)=>{
          const body = new FormData()
          loader.file.then((file)=>{
            body.append("file",file)
            axios.post(`${process.env.REACT_APP_BASE_URL}/blog/upload`,body).then((res)=>{
              resolve(res.data.url)
            }).catch((err)=>{
              console.log(err)
            })
          })
        })
      },
    };
  };

  function uploadPlugin(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    }}
  

export default function Ckeditor({description,setDescription}) {
  return (
    <div className="App">
      <CKEditor
        editor={ClassicEditor}
        data={description}
        onReady={(editor) => {
        }}
        config={{
            extraPlugins: [uploadPlugin],
          }}
        onChange={(event, editor) => {
          const data = editor.getData();
          setDescription(data)
        }}
      />
    </div>
  );
}
