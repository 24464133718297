import React, { useEffect, useState } from "react";
import styles from "./Css/Footer.module.css";
import { getFooterData } from "../utils";
import axios from "axios";
import { Link } from "react-router-dom";
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';

export default function CommonFooter() {
  const [footData,setFootData]=useState()
  useEffect(()=>{
    getFooterData();
  },[])

  const getFooterData = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/footer`)
      .then((res) => {
        setFootData(res.data.footer)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (

    <div >
      <div className="text-white p-5" style={{backgroundColor:"#1976d2"}}>
      <div className="row">
       {
        footData?.map(el=>
      <div className="col-12 col-sm-12 col-md-6 col-lg-3 text-uppercase my-3" key={el._id}>
          <div>
          <h5 className="border-bottom border-grey pb-4">{el.heading}</h5>
          {
            el?.subLink?.map(item=>
             <Link to={item.link} style={{textDecoration:"none",color:"white"}}>
              <div className="border-bottom border-grey py-2"> 
                {item.title}
              </div>
             </Link>
              )
          }
        </div>
      </div>
          )
       }
       <div className={styles["footer-justify"]} >
                    <FacebookIcon onClick={() => { window.open("https://www.facebook.com/indianevisainfo", "_blank") }} />
                    <LinkedInIcon onClick={() => { window.open(" https://www.linkedin.com/company/indianevisa/", "_blank") }} />
                    <TwitterIcon onClick={() => { window.open(" https://twitter.com/indianevisainfo", "_blank") }} />
                    <InstagramIcon onClick={() => { window.open("https://www.instagram.com/indianevisainfo/", "_blank") }} />
                  </div>
    </div>
       </div>
 
      <div className={styles["footer"]}>
        <p>
          © 2023 copyright by <a style={{ color: "#FFF" }} href="https://www.indianevisa.info/">https://www.indianevisa.info</a>
        </p>
        <p>
          Disclaimer: Indian visas obtained through this commercial website must be applied for in person at an Indian government immigration office. The Indian government did not directly, indirectly, or solely appoint <a style={{ color: "#FFF" }} href="https://www.indianevisa.info/">https://www.indianevisa.info</a> to any position. For those who apply on this website, there is a professional cost as well as the government visa levy.
        </p>
      </div>
    </div>
  );
}
