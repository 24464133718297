import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { PaymentValidate } from '../utils';

const appearance = {
    theme: 'stripe'
};

// Pass the appearance object to the Elements instance


const CheckoutForm = (props) => {
    const stripe = useStripe();
    const elements = useElements();
    console.log(props)

    const handleSubmit = async (event) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        const result = await stripe.confirmPayment({
            //`Elements` instance that was used to create the Payment Element
            elements,
            confirmParams: {
                return_url: "https://example.com/order/123/complete",
            },
            redirect: 'if_required',
        });

        if (result.error) {
            const response = await PaymentValidate({
                paymentIntent: result.error.payment_intent,
                payment_method: "stripe"
            })
            if (response.status == 200) {
                window.location.href = "/stripe/success"
            } else {
                window.location.href = "/error"
            }
        } else {
            const response = await PaymentValidate({
                ...result,
                payment_method: "stripe"
            })
            if (response.status == 200) {
                window.location.href = "/stripe/success"
            } else {
                window.location.href = "/error"
            }
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <PaymentElement />
            <div style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                margin: "10px 0px",
                marginTop: "-20px"
            }}>
                <button disabled={!stripe} style={{
                    backgroundColor: 'rgb(25, 118, 210)', // Green background
                    border: 'none', // No borders
                    color: 'white', // White text
                    padding: '15px 32px', // Top and bottom padding, Left and right padding
                    textAlign: 'center', // Centered text
                    textDecoration: 'none', // No underline
                    display: 'inline-block', // Inline block element
                    fontSize: '16px', // Text size
                    margin: '4px 2px', // Margin around the button
                    cursor: 'pointer', // Pointer/hand icon when mouse over
                    borderRadius: '8px', // Rounded corners
                }}>{`Proceed to Pay $${props.price }`}</button>
            </div>
        </form >
    )
};

export default CheckoutForm;